.apprentices-section{
  // background-color: #fff;
  margin-bottom: 4rem;

}


.apprentices__list{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: $border-radius__large;
  border: 1px solid $color__grey--100;

  @include media(">=1024px"){
    padding: 4rem;
  }
}

.apprentices__item{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @include media(">=1024px"){
    gap: 3rem;
    justify-content: flex-start;
  }
}


.apprentices__image{
  img {
    border-radius: $border-radius__medium;

    @include media(">=tablet"){
      max-width: 12rem;
    }

    @include media(">=1024px"){
      max-width: 16rem;
    }
  }
}

.apprentices__content{

  h3{
    font-size: 1.25rem;
    font-weight: 500;
    color: $color__primary;
    color: $color__primary;
    margin-bottom: 0.5em;
  }

  p{
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
    max-width: 50ch;
  }

}



/* apprenticeship */
.apprenticeship__list{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 15rem 7.5rem 7.5rem 15rem;
  grid-gap: 0.5rem;

  @include media(">=tablet"){
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.apprenticeship__item{
  background: $color__grey--100;
  border-radius: $border-radius__medium;

  &--0{
    grid-row: auto;
    @include media(">=tablet"){
      grid-row: 1 / 3;
    }
  }

  &--1{
    grid-row: auto;
    @include media(">=tablet"){
      grid-row: 1 / 4;
    }
  }

  &--2{
    grid-row: auto;
    @include media(">=tablet"){
      grid-row: 1/2;
    }
  }

  &--3{
    grid-row: auto;
    @include media(">=tablet"){
      grid-row: 2 / 4;
    }
  }

  &--4{
    grid-row: auto;
    @include media(">=tablet"){
      grid-row: 3 / 5;
    }
  }

  &--5{
    grid-row: auto;
    @include media(">=tablet"){
      grid-column: 2/-1;
    }
  }

}

.apprenticeship__block{
  padding-bottom: 2rem;
  border-bottom: 1px solid $color__grey--300;

  &:last-child{
    border-bottom: none;
  }
}
