
.project__list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 3rem 2rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid $color__primary--100;
  border-radius: $border-radius__medium;

  @include media('>=tablet'){
    grid-gap: 3rem 4rem;
    padding: 3rem 6rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
}

.project__item{
  display: flex;
  gap: 1rem;
  position: relative;
  flex-wrap: wrap;

  @include media('>=tablet'){
    gap: 1.5rem;
    flex-wrap: nowrap;
  }
}


.project__image{

  @include media('>=tablet'){
    max-width: 8rem;
  }


  picture > img{
    border-radius: $border-radius__medium;
    border: 1px solid $color__primary--200;
  }
}


.project__content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  

  @include media('>=tablet'){
    padding: 0.5rem 0rem;
  }


  h3{
    font-size: 1.125rem;
    font-weight: 500;
    color: $color__primary;
    margin-bottom: 0.5em;
  }

  p{
    line-height: 1.4;
    color: $color__grey--800;
    max-width: 65ch;
    margin-bottom: 0.5rem;

    @include media('>=tablet'){
      margin: 0rem;
    }
  }

  a{
    margin-top: auto;
    color: $color__secondary--400;
    font-weight: 200;

    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}


/* Detail */

.project-detail{
  margin: 4rem 0rem;
}

.project-detail__wrapper{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0rem;

  @include media(">=tablet"){
    grid-template-columns: 2fr 1fr;
    grid-gap: 4rem;
  }
}

.project-detail__content{
  p{
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1em;
    color: $color__grey--900;

    &:last-child{
      margin-bottom: 0rem;
    }
  }
}


.project-detail__sidebar{
  padding: 1rem;
  background-color: $color__grey--100;
  border-radius: $border-radius__medium;

  @include media('>=tablet'){
    padding: 0rem 0rem 0rem 4rem;
    border-left: 1px solid $color__grey--500;
    background-color: transparent;
    border-radius: 0px;
  }

  div{
    margin-bottom: 1rem;
    color: $color__grey--900;
    font-size: 1.125rem;
    line-height: 1.5;
  }

  h3{
    font-size: 0.75rem;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 0.25em;
    color: $color__primary--900;
  }
}

.column-2-4{
  @include media('>=tablet'){
    columns: 2;
    column-gap: 4rem;
  }
}
