.history-section{
  padding: 0rem 1rem;
}

.history__list{
  padding-left: 2rem;
  border-left: 1px solid $color__grey--200;

  @include media('>=tablet'){
    padding-left: 3rem;
  }
}

.history__item{
  position: relative;
  margin-bottom: 4rem;

  h3{
    font-size: 1.25rem;
    font-weight: 500;
    color: $color__primary;
    margin-bottom: 0.5em;
  }

  p{
    line-height: 1.5;
    font-size: 1.125rem;
    color: $color__grey--900;
  }

  &::after{
    content: "";
    position: absolute;
    left: -3rem;
    top: -0.25rem;
    height: 0.875rem;
    width: 0.875rem;
    background-color: $color__primary--200;
    border-radius: 50%;
    border: 8px solid $body-bg;

    @include media('>=tablet'){
      left: -4rem;
    }
  }
}

.history__gallery{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.history__figure{
  aspect-ratio: 3 / 2;
  display: flex;
  margin-top: 1rem;
}

.history__image{
  display: flex;
  border-radius: $border-radius__medium;
  border: 1px solid $color__primary--100;
}
