// class for animation delay
@for $i from 1 through 10 {
  .delay:nth-child(#{$i}n) {
      animation-delay: #{$i * 0.05}s;
  }
}






/*
  TYPING EFFECT
  usage: parent with class typing, child with class typing-effect
*/
.typing {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-effect {
  width: 22ch;
  animation: typing 2s steps(22), effect .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
}

@keyframes typing {
  from {
    width: 0
  }
}

@keyframes effect {
  50% {
    border-color: transparent
  }
}
