#news{
  scroll-margin-top: 2rem;
}

.news__wrapper{
  display: grid;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: 75%;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  grid-gap: 1rem;

  @include media(">=40rem", "screen"){
    grid-auto-columns: calc(50% - 2rem);
  }

  @include media(">=65rem", "screen"){
    grid-template-columns: 1fr 1fr 1fr;
  }
}


.news__item{
  border-radius: $border-radius__medium;
  position: relative;
  aspect-ratio: 3 / 4;
  border-radius: 0.25rem;
  overflow: hidden;

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, $color__grey--900, rgba($color__grey--900, 0));
  }
}

.news__element{
  height: 100%;

}

.news__image-wrapper{
  picture img{
    border-radius: 0.25rem;
    width: 100%;
  }
}

.news__image{
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  left: 0;

  picture{
    display: flex;
  }

}

.news__content{
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2rem 1rem;
  box-sizing: border-box;
  z-index: 10;

  @include media(">=tablet", "screen"){
    padding: 4rem 2rem;
  }


  h2{
    font-size: 1.5rem;
    margin-bottom: 0.5em;
    font-weight: 200;
    color: #fff;
  }

  p{
    font-size: 1.125rem;
    margin-bottom: 2rem;
    line-height: 1.4;
    color: $color__grey--000;

    @include media(">=tablet", "screen"){
      font-size: 1.25rem;
      margin-bottom: 2rem;
      line-height: 1.5;
    }
  }

  a{
    font-size: 1.125rem;
    color: $color__secondary;
  }
}



/* News Grid */
.news-grid{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 1rem;

}

.news-grid__cell{
  background-color: #fff;
  border-radius: $border-radius__medium;
  border: 1px solid $color__grey--100;
  padding: 1rem;
  padding-bottom: 2rem;








  // &:first-child{
  //   grid-column: 1 / -1;
  //   aspect-ratio: auto;
  //   display: grid;
  //   grid-template-columns: 1fr 4fr;
  // }
  //
  // &:nth-child(10n + 5){
  //   grid-column: 1 / 3;
  //   aspect-ratio: auto;
  //   display: grid;
  // }
  //
  // &:nth-child(10n + 11){
  //   grid-column: 2 / -1;
  //   aspect-ratio: auto;
  //   display: grid;
  // }

}


.news-cell__image{
  margin-bottom: 1.5rem;

  picture > img{
    border-radius: $border-radius__small;
  }
}

.news-cell__content{
  padding: 0.5rem;

  h2{
    font-size: 1.25rem;
    margin-bottom: 0.5em;
    color: $color__primary;
  }

  p{
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
    margin-bottom: 1em;
  }

  a{
    color: $color__secondary;
    text-decoration: underline;
  }
}
