
.benefit__list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 4rem;
  padding: 4rem 0rem;
  margin-bottom: 2rem;
}

.benefit__item{
  
  // border: 1px solid $color__primary--600;
  // padding: 2rem;

  span{
    display: block;
    margin-bottom: 3rem;

    & > svg{
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  h3{
    font-size: 1.25rem;
    font-weight: 500;
    color: $color__primary--900;
    margin-bottom: 0.5em;
  }

  p{
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
  }
}
