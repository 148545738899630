// Font-size + Line-height + Kerning
// Usage: @include font-size(1, mobile)
// Add more true/false args to control what to output: font-size, line-height, kerning
@mixin font-size($size, $elem, $font-size: true, $line-height: false, $kerning: false, $adjust-font-size: 0) {
	@if not map-has-key(map-get($font__scale, $elem), $size) {
		@warn "'#{$size}' key does not exist in array!";
	}
	@if ( $font-size != false ) {
		font-size: get-font-size($size, $elem) + $adjust-font-size;
	}
	@if ( $line-height == true ) {
		line-height: get-line-height($size, $elem);
	}
	@if ( $kerning == true ) {
		letter-spacing: get-kerning($size, $elem);
	}
}


// Font Family
@mixin font-family($elem) {
	font-family: unquote(get-font-family($elem));
}

// Font Weight
// Usage: @include font-weight(regular)
@mixin font-weight($elem) {
	font-weight: get-font-weight($elem);
}


// Text shortening
@mixin text-shorten {
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
}



/*
	BOOK
	BETTER WEB TYPOGRAPHY FOR BETTER WEB

*/
// muss zu den variables
$base: 1.125; //= 18px
$ratio: 1.5; // Perfect Fifth
$line-height: 1.45; // base line-height

// [ h1: font-size: [x value from scale], line-height: 3 × 26px, margin-top: 2 × 26px, margin-bottom: 1 × 26px ]
// font-size, line-height, margin-top, margin-bottom,
$headings: (
  h1: (3, 3, 2, 1),
  h2: (1, 1.5, 2, 1),
	h3: (0, 1, 1, 0),
  h4: (0, 1, 1, 0),
  h5: (0, 1, 1, 0),
  h6: (0, 1, 1, 0)
);


@function pow($number, $exponent) {
  $value: 1;

	// positive scale
  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $number; //Multiply by $number if exponent less than zero
    }
  }
	// negative scale
	@else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $number; //Divide by $number if exponent less than zero
    }
  }

  @return $value;
}

@function ms($value, $ms-ratio: $ratio, $ms-base: $base){
  $size: pow($ms-ratio, $value)*$ms-base;
  @return #{ $size + "rem" };
}


@mixin line-height($number) {
  line-height: #{ $number * $line-height + 'rem'};
}

@mixin margin-top($number) {
  margin-top: #{ $number * $line-height + 'rem'};
}

@mixin margin-bottom($number) {
  margin-bottom: #{ $number * $line-height + 'rem'};
}


// Set font-size, line-heights and margins
@each $heading, $properties in $headings {
  .#{$heading} {

		// idee: properties eins kleiner machen, damit auf dem phone
		// der font kleiner wird und media query einfügen

    font-size: ms(nth($properties, 1));
    @include line-height(nth($properties, 2));
    @include margin-top(nth($properties, 3));
    @include margin-bottom(nth($properties, 4));
  }
}






/* Aspect Ratio Mixin */
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

// edit font rendering --> use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
