.text__block{
  margin-bottom: 4rem;

  h1{
    font-size: 1.875rem;
    font-weight: 800;
    color: $color__primary--800;
    margin-bottom: 1em;
    margin-top: 2em;
    line-height: 1.2;
  }


  h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: $color__primary--900;
    margin-bottom: 1em;
    margin-top: 2em;
    line-height: 1.4;
  }


  h3{
    font-size: 1.25rem;
    margin-bottom: 0.5em;
    margin-top: 1em;
    line-height: 1.4;
    color: $color__primary--800;
  }


  p{
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
    margin-bottom: 1em;
    max-width: 65ch;

    a{
      text-decoration: underline;
    }
  }

a{
  text-decoration: underline;
}

  ul > li{
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
  }

  hr{
    border-top: 1px solid $color__grey--200;
    margin: 2rem 0rem;
  }



}
