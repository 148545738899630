.job-tender-image{
  margin-bottom: 2rem;
  margin-top: 2rem;

  @include media(">=tablet", "screen"){
    margin-bottom: 8rem;
  }
}

.job-tender__image{
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: $border-radius__medium;

  @include media(">=tablet", "screen"){
    aspect-ratio: 3 / 1;
  }
}


.job-tender{
  box-sizing: border-box;
  padding: 1rem;
}


.job-tender__wrapper{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  position: relative;

  @include media(">=tablet", "screen"){
    grid-template-columns: 1fr 1.5fr;
  }
}


.job-tender__aside{
  

  @include media(">=tablet", "screen"){
    position: sticky;
    top: 2rem;
    align-self: start;
  }
}

.job-tender__heading{
  margin-bottom: 3rem;

   h1{
     font-size: 2rem;
     font-weight: 500;
     color: $color__primary;
     line-height: 1.25;
     margin-bottom: 0.25em;
   }

   p{
     color: $color__grey--900;
   }
}


.job-tender__content{

  .job-tender__lead{
    font-size: 1.5rem;
    font-weight: 500;
    color: $color__primary--900;
    margin-bottom: 2em;
    line-height: 1.4;
  }
}

.job-tender__infobox{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.job-tender__box-item{
  color: $color__grey--900;
}


.job-tender__tagline{
  font-size: 0.75rem;
  font-weight: 800;
  text-transform: uppercase;
  color: $color__primary--900;
  margin-bottom: 0.5em;
}


.job-tender__contact{
  padding-top: 2rem;
  margin-bottom: 4rem;
  border-top: 1px solid $color__grey--200;

  span{
    display: block;
    margin-bottom: 1rem;
    font-weight: 500;
  }

  h3{
    color: $color__primary--900;
    font-weight: 500;
    font-size: 1.25rem;
  }

  & > a{
    margin-bottom: 2rem;
  }
}
