/* COLORS */
// $body-bg: hsla(240,9%,98%,1);
$body-bg: hsla(204,45%,98%, 1);

// primary
$color__primary: hsla(208,100%,33%, 1);
$color__primary--000: hsla(204,45%,98%, 1);
$color__primary--100: hsla(206, 47%, 93%, 1);
$color__primary--200: hsla(207,49%, 88%, 1);
$color__primary--300: hsla(207, 49%, 83%, 1);
$color__primary--400: hsla(207, 48%, 77%, 1);
$color__primary--500: hsla(206,48%, 70%, 1);
$color__primary--600: hsla(207,48%, 63%, 1);
$color__primary--700: hsla(207,49%, 54%, 1);
$color__primary--800: hsla(207,70%, 41%, 1);
$color__primary--900: hsla(207,100%, 23%, 1);

// secondary
$color__secondary: hsla(51,100%, 50%, 1);
$color__secondary--000: hsla(51,100%,94%, 1);
$color__secondary--100: hsla(51, 100%, 79%, 1);
$color__secondary--200: hsla(51,100%, 60%, 1);
$color__secondary--300: hsla(51, 100%, 48%, 1);
$color__secondary--400: hsla(51, 100%, 45%, 1);
$color__secondary--500: hsla(51,100%, 41%, 1);
$color__secondary--600: hsla(51,100%, 36%, 1);
$color__secondary--700: hsla(51,100%, 31%, 1);
$color__secondary--800: hsla(51,100%, 25%, 1);
$color__secondary--900: hsla(51,100%, 15%, 1);


// greytones
$color__grey--000: hsla(240,9%,98%, 1);
$color__grey--100: hsla(200, 9%, 93%, 1);
$color__grey--200: hsla(210,7%, 88%, 1);
$color__grey--300: hsla(210, 7%, 83%, 1);
$color__grey--400: hsla(210, 7%, 77%, 1);
$color__grey--500: hsla(207,6%, 70%, 1);
$color__grey--600: hsla(205,6%, 63%, 1);
$color__grey--700: hsla(208,6%, 54%, 1);
$color__grey--800: hsla(210,8%, 43%, 1);
$color__grey--900: hsla(210,13%, 25%, 1);


// success
$color__success--000: hsla(0,0%,98%, 1);
$color__success--100: hsla(0, 0%, 96%, 1);
$color__success--200: hsla(0,0%, 93%, 1);
$color__success--300: hsla(0, 0%, 88%, 1);
$color__success--400: hsla(0, 0%, 74%, 1);
$color__success--500: hsla(0,0%, 62%, 1);
$color__success--600: hsla(0,0%, 46%, 1);
$color__success--700: hsla(0,0%, 38%, 1);
$color__success--800: hsla(0,0%, 26%, 1);
$color__success--900: hsla(0,0%, 13%, 1);


// info
$color__info--000: hsla(0,0%,98%, 1);
$color__info--100: hsla(0, 0%, 96%, 1);
$color__info--200: hsla(0,0%, 93%, 1);
$color__info--300: hsla(0, 0%, 88%, 1);
$color__info--400: hsla(0, 0%, 74%, 1);
$color__info--500: hsla(0,0%, 62%, 1);
$color__info--600: hsla(0,0%, 46%, 1);
$color__info--700: hsla(0,0%, 38%, 1);
$color__info--800: hsla(0,0%, 26%, 1);
$color__info--900: hsla(0,0%, 13%, 1);

// warning
$color__warning--000: hsla(0,0%,98%, 1);
$color__warning--100: hsla(0, 0%, 96%, 1);
$color__warning--200: hsla(0,0%, 93%, 1);
$color__warning--300: hsla(0, 0%, 88%, 1);
$color__warning--400: hsla(0, 0%, 74%, 1);
$color__warning--500: hsla(0,0%, 62%, 1);
$color__warning--600: hsla(0,0%, 46%, 1);
$color__warning--700: hsla(0,0%, 38%, 1);
$color__warning--800: hsla(0,0%, 26%, 1);
$color__warning--900: hsla(0,0%, 13%, 1);

// error
$color__error--000: hsla(0,0%,98%, 1);
$color__error--100: hsla(0, 0%, 96%, 1);
$color__error--200: hsla(0,0%, 93%, 1);
$color__error--300: hsla(0, 0%, 88%, 1);
$color__error--400: hsla(0, 0%, 74%, 1);
$color__error--500: hsla(0,0%, 62%, 1);
$color__error--600: hsla(0,0%, 46%, 1);
$color__error--700: hsla(0,0%, 38%, 1);
$color__error--800: hsla(0,0%, 26%, 1);
$color__error--900: hsla(0,0%, 13%, 1);

/* SHADOWS */
$shadow--xsmall: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
$shadow--small: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
$shadow--medium: 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.20);
$shadow--large: 0 12px 17px 2px rgba(0,0,0,0.14), 0 5px 22px 4px rgba(0,0,0,0.12), 0 7px 8px -4px rgba(0,0,0,0.20);
$shadow--xlarge: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.20);

// $shadow--medium: 0 8px 10px 1px rgba($color__primary,0.14), 0 3px 14px 2px rgba($color__primary,0.12), 0 5px 5px -3px rgba($color__primary,0.20);

/* BORDER-RADIUS */
$border-radius--small: 0.125rem;
$border-radius--medium: 0.25rem;
$border-radius--large: 0.5rem;
$border-radius--xlarge: 0.75rem;

/* GAP */
$gap--small: 0.25rem;
$gap--medium: 0.5rem;
$gap--large: 1rem;
$gap--xlarge: 1.5rem;


/* FONTS */
$font-family__headings: 'Inter', sans-serif;
$font-family__base: 'Inter', sans-serif;


$font__family: (
	base: '"Inter", sans-serif',
	heading: '"Inter", sans-serif',
);


// font-size, line-height, kerning (use '0' if don't want to output any kerning)
$font__sizes: (
	alpha:   ( 3rem, 1.2, 0), // 48px
	beta:    ( 2rem, 1.2, 0), // 32px
	gamma:   ( 1.5rem, 1.2, 0), // 24px
	delta:   ( 1.25rem, 1.4, 0), // 20px
	epsilon: ( 1.125rem, 1.4, 0), // 18px
	zeta:    ( 1rem, 1.4, 0), // 16px
	eta:     ( 0.875rem, 1.4, 0), // 14px
);

$font__scale: (
	desktop: (                             // i.e. $breakpoint__m + $breakpoint__l (600 - 1024)
		1: map-get($font__sizes, alpha),   // H1
		2: map-get($font__sizes, beta),    // H2
		3: map-get($font__sizes, gamma),   // H3
		4: map-get($font__sizes, delta),   // H4, H5, H6
		5: map-get($font__sizes, epsilon),   // Body
		6: map-get($font__sizes, zeta), // Text small (e.g. features description)
		7: map-get($font__sizes, zeta)    // Text smaller (e.g. pricing table's lists)
	),
	mobile: (                              // i.e. $breakpoint__xs + $breakpoint__s (up to 600)
		1: map-get($font__sizes, beta),    // H1
		2: map-get($font__sizes, gamma),   // H2
		3: map-get($font__sizes, delta),   // H3
		4: map-get($font__sizes, epsilon), // H4, H5, H6
		5: map-get($font__sizes, zeta), // Body
		6: map-get($font__sizes, eta) // Text small (e.g. features description)
	)
);


$font__weight: (
	regular: 400, 	// font__weight(regular)
	medium: 500,	// font__weight(medium)
	semibold: 600,	// font__weight(semi-bold)
	bold: 800		// font__weight(bold)
);


/* LAYOUT */

// max-width
$max-width--xl: 120rem;
$max-width--l: 90rem;
$max-width--m: 75rem;
$max-width--s: 60rem;
$max-width--xs: 45rem;


/* ANIMATION */
$ease-in-quad:       cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic:      cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart:      cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint:      cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-expo:       cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ:       cubic-bezier(0.6, 0.04, 0.98, 0.335);

$ease-out-quad:      cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic:     cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart:     cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint:     cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-expo:      cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ:      cubic-bezier(0.075, 0.82, 0.165, 1);

$ease-in-out-quad:   cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic:  cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart:  cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint:  cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-expo:   cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ:   cubic-bezier(0.785, 0.135, 0.15, 0.86);



/* OVERLAY */

$overlay_ltr : linear-gradient(
    to right,
    hsla(210,13%, 25%, 1) 0%,
    hsla(210,13%, 25%, 0.987) 8.1%,
    hsla(210,13%, 25%, 0.951) 15.5%,
    hsla(210,13%, 25%, 0.896) 22.5%,
    hsla(210,13%, 25%, 0.825) 29%,
    hsla(210,13%, 25%, 0.741) 35.3%,
    hsla(210,13%, 25%, 0.648) 41.2%,
    hsla(210,13%, 25%, 0.55) 47.1%,
    hsla(210,13%, 25%, 0.45) 52.9%,
    hsla(210,13%, 25%, 0.352) 58.8%,
    hsla(210,13%, 25%, 0.259) 64.7%,
    hsla(210,13%, 25%, 0.175) 71%,
    hsla(210,13%, 25%, 0.104) 77.5%,
    hsla(210,13%, 25%, 0.049) 84.5%,
    hsla(210,13%, 25%, 0.013) 91.9%,
    hsla(210,13%, 25%, 0) 100%
  );
