.site-header{
  height: 6rem;
  display: flex;
  background-color: #fff;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, $color__secondary 25%, $color__primary 75%);
  border-image-slice: 1;
  width:100%;
  padding: 1rem;
  box-sizing: border-box;

  @include media('<=tablet'){
    height: 5rem;
    padding: 0rem;
  }

  &--no-border{
    border: none;
  }
}

.site-header__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


/* Video Hero Header*/
.video-hero__wrapper{
  position: relative;
  z-index: 15;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;

  & > div{
    display: flex;
    align-items: center;
  }
}

.video-hero__play-button{
  display: none;
  @include media('>=tablet'){
    display: block;
  }


  button{

    svg{
      height: 4rem;
      width: 4rem;

      @include media('>=1024px'){
        height: 6rem;
        width: 6rem;
      }
    }
  }


}

.video-hero__scroll-button{
  display: none;

  @include media('>=desktop'){
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    z-index: 100;
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
    border: 2px solid white;
    
    border-radius: 50em;
    padding: 0.25rem;
    cursor: pointer;
  }
}


.video-hero__heading{
  font-size: clamp(1.875rem, 4vw, 3.5rem);
  font-weight: 800;
  max-width: 20em;
  margin-bottom: 0.75em;
  background: linear-gradient(135deg, $color__secondary--100 15%, $color__secondary 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-hero__lead{
  font-size: clamp(1.25rem, 2vw, 1.875rem);;
  max-width: 25em;
  line-height: 1.5;
  font-weight: 200;
  margin-bottom: 2em;
  color: $color__grey--000;
}




/* Default Hero Header */
.hero-header-section{
  margin-bottom: 4rem;
}

.hero-header__container{
  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $overlay_ltr;
  }
}



.hero-header__image{
  grid-column: 1/-1;
  grid-row: 1/-1;

  img{
    width: 100%;
  }
}


.hero-header__content-container{
  margin-top: -3vw;
}

.hero-header__content{
  position: relative;
  grid-column: 1/-1;
  grid-row: 1/-1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;



  .wrapper{
    width: 100%;
  }
}

.hero-header__title{
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  margin-bottom: 0.5em;
  color: $color__secondary--400;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 200;
}




.hero-header__text{

  h2{
    font-size: clamp(2rem, 4vw, 3.5rem);
    font-weight: 600;
    margin-bottom: 0.5em;
    letter-spacing: -1px;
    background: linear-gradient(135deg, hsl(51deg, 100%, 79%) 15%, hsl(51deg, 100%, 50%) 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: fit-content;
  }

  p{
    font-size: clamp(1.125rem, 3vw, 1.875rem);
    line-height: 1.5;
    color: $color__grey--000;
    max-width: 42ch;
    font-weight: 200;
  }
}



/* Logo */
.site-logo{
  display: flex;
  align-items: center;
}

.site-logo svg {
  height: 2.25rem;
  width: auto;

  @include media('<=tablet'){
    height: 1.875rem;
  }
}




/* Navigation */


// Mobile Menu Button
#menubutton{
  display: grid;
  grid-gap: 0.25rem;
  padding: 0rem;
  min-width: 0rem;
  margin-right: 0.5rem;

  @include media('>=1024px'){
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  &[aria-expanded="true"]{
    
    .icon-menu__top{
      transform: translateY(1px) translateX(0) rotate(45deg);
      transform-origin: center;
      transition: transform 0.2s ease-in-out;
    }

    .icon-menu__bottom{
      transform: translateY(-5px) translateX(0) rotate(-45deg);
      transform-origin: center;
      transition: transform 0.2s ease-in-out;
    }

    .icon-menu__center{
      display: none;
    }
  }
}

.icon-menu__top{
  display: block;
  width: 2rem;
  height: 0.15rem;
  background-color: $color__primary;
  transform: translateX(0.5rem);
  transition: transform 0.2s ease-in-out;
}

.icon-menu__center{
  display: block;
  width: 2rem;
  height: 0.15rem;
  background-color: $color__secondary;
  transform: translateX(0.25rem);
}

.icon-menu__bottom{
  display: block;
  width: 2rem;
  height: 0.15rem;
  background-color: $color__primary;
  transition: transform 0.2s ease-in-out;
}






.site-nav{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


.site-nav__list{
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media('<=1024px', 'screen'){
    display: none;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    height: calc(100% - 5rem);
    background: rgba(255,255,255,0.95);
    flex-direction: column;
    justify-content: center;
    z-index: 20;
    align-items: flex-start;
    box-sizing: border-box;
    padding-bottom: 8vh;

    &.is-active{
      display: flex;
    }
  }
}

.site-nav__item{
  position: relative;
  line-height: 1;
  color: $color__primary;
  font-size: 1.5rem;
  padding: 1rem 1.5rem;

  @include media('>=1024px'){
    font-size: 1rem;
    padding: 1rem;
  }

  @include media('>=1200px'){
    padding: 1rem 1.5rem;
    font-size: 1.125rem;
  }

  


  &:last-child{
    margin-left: 2rem;
    background-color: $color__primary;
    color: #fff;
    border-radius: $border-radius__medium;
    font-size: 0.875rem;
    padding: 0.875em 1.5em;

    @include media('<=1024px'){
      margin-left: 0rem;
      background-color: transparent;
      color: $color__primary;
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
    }
  }
}


.site-nav__link{
  display: flex;
  gap: 0.5rem;
  align-items: center;

  &.is-active{
    font-weight: 800;
  }
}

.site-nav__link-icon{
  display: block;
  border-right: 1px solid $color__primary;
  border-bottom: 1px solid $color__primary;
  transform: rotate(45deg);
  height: 0.45rem;
  width: 0.45rem;
  margin-top: -0.25rem;
}


.site-nav__item > nav{
  position: absolute;
  padding: 0.5rem;
  background: white;
  left: -25%;
  z-index: 20;
  border-radius: $border-radius__medium;
  border: 1px solid $color__primary;
  box-shadow: $shadow--medium;
  top: 4rem;
  min-width: 16rem;


    @include media('>=1024px'){
      &::before{
        content: "";
        position: absolute;
        top: -0.55rem;
        left: calc(50% - 0.5rem);
        transform: rotate(45deg);
        height: 1rem;
        width: 1rem;
        background-color: #fff;
        border-left: 1px solid $color__primary;
        border-top: 1px solid $color__primary;
        border-top-left-radius: 0.125rem;
    }
  }


  @include media('<=1024px'){
    position: relative;
    left: 0;
    border: none;
    background-color: transparent;
    box-shadow: none;
    top: 0;
    min-width: 0;
    padding: 0rem;
  }
}

.site-nav__sub-list{
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  @include media('<=1024px'){
    padding: 1rem 0rem;
  }
}

.site-nav__sub-item{
  border-radius: $border-radius__small;
  color: $color__primary--800;
  transition: background 0.1 ease-in-out;

  @include media('<=1024px'){
    font-size: 1.25rem;
  }


  @include media('>=1024px'){
    color: $color__primary--900;
    &:nth-child(even){
      background-color: rgba($color__primary--100, 0.25);
    }
  }
  


  &:hover{
    background-color: rgba($color__primary--100, 1);
    transition: background 0.1 ease-in-out;
  }

  &.is-active{
    font-weight: 800;
  }

  a{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include media('<=tablet'){
      padding: 0.25rem;
      gap: 0.5rem;
    }

    span{
      display: flex;
    }

    svg{
      height: 2.25rem;
      width: 2.25rem;

      @include media('<=tablet'){
        height: 2rem;
        width: 2rem;
      }
    }
  }
}
