html{
  scroll-behavior: smooth;
}


body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: $font-family__base;
  text-align: left;
  background-color: $body-bg;
  min-height: 100vh;
}


a{
  all: unset;
  all: revert;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: block;
  white-space: nowrap;
}

button{
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  min-width: 6rem;
  padding: 1rem 1.5rem;
}


button[disabled] {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

// Correct the inability to style clickable types in iOS and Safari
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

// Remove the inner border and padding in Firefox
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

h1, h2, h3, h4, h5, h6{
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p{
  margin: 0;
  hyphens: auto;
}

q{
  &:before,
  &:after{
    content: '';
  }
}

blockquote{
  margin: 0;
}

address{
  font-style: normal;
}

figure{
  margin: 0;
}

img{
  max-width: 100%;
  display: block;
  height: auto;
  object-fit: cover;
}

// don't show images without src or scrcset (lazy loading helper)
img:not([src]):not([srcset]) {
  visibility: hidden;
}


input{
  border-radius: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}


fieldset{
  border: none;
}

// remove pointer event from svg inside button
button > * {
  pointer-events: none;
}

textarea{
  resize: none;
}


hr{
  color: #fff;
}