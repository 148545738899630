label{
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  color: $color__grey--900;
}

/* Input */
input[type='text'],
input[type='password'],
input[type='email'],
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='number'],
input[type='url']{
  height: 2.5rem;
  border: 1px solid $color__grey--500;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-family: $font-family__base;
  border-radius: 0.125rem;
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;


  &:focus{
    border: 1px solid $color__grey--700;
    outline: none;
  }

  // &:invalid{
  //   border: 1px solid $color__error--200;
  //   outline: none;
  // }

  &:disabled{
    background: $color__grey--500;
  }

  &::placeholder{
    font-size: 0.875rem;
    color: $color__grey--700
  }


  &.error{
    border: 1px solid $color__error--500;
    outline: 1px solid $color__error--500;
  }
}

.input__alert{
  font-size: 0.875rem;
  margin-top: 0.25rem;
  font-weight: 200;

  &--info{
    color: $color__info--500;
  }

  &--warning{
    color: $color__warning--500;
  }

  &--error{
    color: $color__error--500;
  }
}


/* Textarea */
textarea{
  box-sizing: border-box;
  border: 1px solid $color__grey--400;
  padding: 0.5rem;
  width: 100%;

  &:focus{
    border: 1px solid $color__primary--700;
    outline: none;
  }
}

/* Layout */
.form__fieldset{
  display: flex;
  flex-direction: column;

  &--row{
    flex-direction: row;
    gap: 0.25rem;
  }
}



.form__group{
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &--row{
    flex-direction: row;
    margin-bottom: 0rem;
  }

  &--radio{

    label{
      cursor: pointer;
      padding: 0.65em 1.25em;
      margin-bottom: 0rem;
      background-color: $color__grey--100;
      color: $color__grey--500;
    }

    input:checked ~ label{
      background-color: $color__primary;
      color: #fff;
    }
  }
}
