

.btn{
  position: relative;
  border: 1px solid transparent;
  border-radius: $border-radius__small;

  padding: 0.75rem 1.5rem;
  font-size: 1rem;

  .icon {
    /* icon inherits color of button label */
    color: inherit;
    flex-shrink: 0;

    svg{
      fill: inherit;
    }
  }


  &--small{
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }

  &--medium{
    padding: 0.875em 1.5em;
    font-size: 1rem;
    border-radius: $border-radius__medium;

    @include media('>=tablet'){
      font-size: 1.125rem;
    }
  }

  &--large{
    padding: 1em 1.5em;
    font-weight: 500;
    font-size: 1.25rem;
  }
}

.btn__primary{
  background-color: $color__primary;
  color: #fff;


  &:hover{}

  &:focus{}

  &:active{}

  &:disabled{}

  &:visited{}
}

.btn__secondary{
  background-color: $color__secondary;
  border: 1px solid $color__secondary;
  color: $color__grey--900;
  transition: background 0.2s ease-in-out;


  &:hover{
    background-color: rgba($color__secondary, 0.5);
    transition: background 0.2s ease-in-out;
  }

  &:focus{}

  &:active{}

  &:disabled{}

  &:visited{}
}

.btn__neutral{
  background-color: #fff;
  border: 1px solid #fff;
  color: $color__grey--900;
  transition: background 0.2s ease-in-out;


  &:hover{
    background-color: rgba(#fff, 0.5);
    transition: background 0.2s ease-in-out;
  }
}


// button group
.btn__group {
  display: flex;
  flex-wrap: wrap;

  > * {
      margin-right: 0.5rem;

      &:last-of-type {
        margin-right: 0;
      }
  }
}
