.employee-section{
  background-color: $color__primary--100;
  border: 1px solid $color__primary--200;
}



.employee__item{
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
  padding-top: 2rem;
  flex-direction: column;
  gap: 1rem;

  @include media(">=695px"){
    flex-direction: row;
    align-items: center;
    padding-top: 0rem;
    gap: 0rem;
  }

  h2{
    font-size: 1.875rem;
    font-weight: 500;
    margin-bottom: 0.25em;
    color: $color__primary;
  }

  p{
    font-size: 1.25rem;
    margin-bottom: 1.5em;
    color: $color__grey--900;
    color: $color__primary--800;
  }

  .block{
    font-size: 1.125rem;
    // font-weight: 200;
    line-height: 1.5;
    color: $color__grey--900;
    p{
      color: inherit;
      margin-bottom: 0rem;
      font-size: 1.125rem;
    }
  }

}

.employee__image{
 max-width: 24rem;

//  @include media("<=tablet"){
//   max-width: 16rem;
//  }

}
