
.service-section{
  margin: 4rem 0rem;
}

.service__list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 4rem;
  padding: 1rem;
  box-sizing: border-box;
  
  // border-radius: $border-radius__medium;
  // background-color: rgba($color__secondary, .05);
  // border: 1px solid rgba($color__secondary, 0.15);

  @include media(">=tablet", "screen"){
    padding: 4rem;
  }
}


.service__item{

  & > span{
    height: 3rem;
    display: block;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
  }

  h3{
    font-size: 1.5rem;
    color: $color__primary;
    font-weight: 500;
    margin-bottom: 0.75em;
  }

  p{
    font-size: 1.125rem;
    line-height: 1.4;
    margin-bottom: 2rem;
    color: $color__grey--900;
  }

  ul > li{
    line-height: 1.5;
    font-size: 1.125rem;
    color: $color__grey--900;
    margin-bottom: 0.25rem;

    span{
      padding-right: 0.125rem;
    }
  }
}
