.video__lightbox{
 box-sizing: border-box;
 position: fixed;
 top: 0;
 left: 0;
 height: 100svh;
 width: 100vw;
 padding: 2rem;
 background: rgba($color__grey--900, 0.9);
 display: grid;
 place-items: center;
 z-index: 100;
}


.video__lightbox-container{
 width: 100%;

 @include media(">=tablet"){
  width: 65vw;
 }
}


.video__close{
 color: #fff;
 position: fixed;
 bottom: 2rem;
 left: 50%;
 transform: translateX(-50%);
 border: 1px solid #fff;
 border-radius: 0.25rem;
}