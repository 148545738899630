.latest-news-section{
  background-color: #fff;
  border-top: 1px solid $color__grey--100;
  padding: 4rem 0rem;
}

.latest-news__wrapper{
  h2{
    font-size: 1.5rem;
    font-weight: 500;
    color: $color__primary;
  }
}


.latest-news__list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-gap: 4rem;
  padding-top: 3rem;
}

.latest-news__item{
  // padding-left: 1rem;
  // border-left: 1px solid $color__primary--200;


  h3{
    font-size: 1.125rem;
    font-weight: 500;
    color: $color__primary--900;
    margin-bottom: 0.25em;
  }

  p{
    color: $color__grey--900;
    line-height: 1.5;
    margin-bottom: 1rem;
    max-width: 20em;
  }

  a{
    color: $color__secondary--400;
    font-weight: 200;
  }
}
