
.gallery-section{
  position: relative;
  margin: 4rem 0rem;

  @include media('<=tablet', 'screen') {
    &::after{
      content: '';
      position:absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 5rem;
      background: linear-gradient(to left, rgba($body-bg,1), rgba($body-bg,0));
    }
  }
}

.gallery__container{
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: column;
  grid-auto-columns: 75%;
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  margin-bottom: 1rem;

  @include media('>=tablet', 'screen') {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    grid-auto-columns: auto;
    grid-auto-flow: row;
  }
}


.gallery__image{
  width: 100%;
  border-radius: $border-radius__medium;
}




