.video__container{
  background: $color__primary;
  position: relative;
  aspect-ratio: 4 / 5;

  @include media('>=tablet'){
    aspect-ratio: 2.5 / 1;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color__grey--900, 0.5);
  }


  iframe{
    opacity: 0.85;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}


.desktop-video{
  display: none;
  @include media('>=tablet'){
    display: block;
  }
}


.mobile-video{
  display: block;
  @include media('>=tablet'){
    display: none;
  }
}
