.jobs-section{
  margin: 4rem 0rem;
}

.jobs__wrapper{
  box-sizing: border-box;
  background-color: #fff;
  padding: 2rem 2rem 0rem 2rem;
  border-radius: $border-radius__medium;
  border: 1px solid $color__grey--100;
}

.jobs__list{
  padding: 1rem 0rem;
}

.jobs__item{
  padding: 1.5rem 0;
  border-bottom: 1px solid $color__grey--100;

  @include media(">=tablet", "screen"){
    padding: 1rem;
  }

  &:last-child{
    border: none;
  }

  &:hover{
    background-color: $color__primary--000;
  }
}

.jobs__link{
  display: grid;
  grid-template-columns: repeat(auto-fit, 1fr);
  align-items: center;
  color: $color__grey--900;

  @include media(">=tablet", "screen"){
    grid-template-columns: 2fr 0.5fr 0.5fr auto;
  }

}

.job__title{
  // color: $color__primary--900;
  font-size: 1.125rem;
  font-weight: 400;
  white-space: break-spaces;
}

.job__start{
  color: $color__grey--800;
}

.job__percent{

  display: flex;

  p{
    font-size: 0.875rem;
    border-radius: 50px;
    padding: 1.5em 0;

    @include media(">=tablet"){
      background: $color__grey--000;
      padding: 0.5em 1em;
    }
  }
}

.job__icon{
  color: $color__primary;
}
