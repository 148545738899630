.customer-section{
  padding: 4rem 0rem;
}

.customer__wrapper{
  
  h3{
    text-align: center;
    margin-bottom: 2.5rem;
    color: $color__primary--700;
    color: $color__grey--800;
    font-weight: 200;
    font-size: 0.875rem;
  }
}


.customer__list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  gap: 2rem;
  align-items: center;
  justify-items: center;
}


.customer__link{
  display: flex;
  align-items: center;
  justify-content: center;
}
