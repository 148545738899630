.contact-form-section{
  background-color: rgba($color__primary--100, 0.25);
  border-top: 1px solid $color__primary--100;
  padding: 4rem 1rem;

  @include media(">=tablet", "screen"){
    padding: 4rem 0rem;
  }
}

.contact-section{
  margin: 4rem 0rem;
}

.contact-form__container{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;

  @include media(">=tablet", "screen"){
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  @include media(">=desktop", "screen"){
    grid-gap: 4rem;
  }

  aside{
    min-height: 50vh;

    @include media(">=tablet", "screen"){
      min-height: auto;
    }
  }
}
