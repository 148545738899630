.site-footer{
  margin-top: auto;
  background-color: $color__primary;
  color: #fff;
}


.main__footer{
  padding: 4rem 0rem;
}


.top-row,
.bottom-row{
  display: grid;
  grid-template-columns:  repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 3rem;
}


.sub__footer{
  background-color: $color__primary--900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem;
  font-size: 0.875rem;
  font-weight: 200;
  flex-wrap: wrap;
  gap: 1rem;
}


.footer__item{
  h3{
    font-weight: 600;
    margin-bottom: 0.25em;
  }

  address{
    line-height: 1.5;
  }

  ul > li{
    line-height: 1.5;
  }
}
