

.site-tagline{}

.site-heading{
  font-size: 2rem;
  margin: 2rem 0rem;
  font-weight: 500;
  color: $color__primary;
}

.site-subheading{}



.section-headline{
  margin-bottom: 4rem;
}

.section-tagline{}

.section-heading{
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.25em;
  color: $color__primary;

  /* test */
  // font-size: 3rem;
  // font-weight: 800;
  // letter-spacing: 2px;
  // text-transform: uppercase;
}


.section-subheading{
  font-size: 1.125rem;
  color: $color__grey--900;
}
