.browserupgrade{
  position: relative;
  text-align: center;
  padding: 2rem;
  font-size: 1.125rem;
  z-index: 100;

  a{
    text-decoration: underline;
  }
}


.max-char{
  max-width: 60ch;
}

// reset list styles
.list-reset{
  list-style: none;
  margin: 0;
  padding: 0;
}

// prevent scrolling
.no-scroll{
  overflow: hidden;
}

// hide scrollbar
.no-scrollbar{
  scrollbar-width: none;

  &::-webkit-scrollbar {
      display: none;
  }
}

// hide arrows in numberfield
.no-arrows{

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

}

.no-resize{
  resize: none;
}


// used for visually hide link labels but let accessible
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


.system-fonts{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.no-hyphens{
  hyphens: none;
}

// image cover
.cover{
  overflow: hidden;

  img{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

// break out of the flow
.full-bleed{
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

// make numbers monospace if font supports it
.is-number {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.flex{
  display: flex;

  &.space-between{
    justify-content: space-between;
  }
}

.image-clip{
  clip-path: polygon(100% 0, 100% 80%, 95% 100%, 0 100%, 0 0);

  picture > img{
    border-radius: $border-radius__medium;
  }
}
