.certificates__list{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;

  @include media(">=480px", "screen"){
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  @include media(">=tablet", "screen"){
    grid-template-columns: 1fr;
  }
}


.certificates-section{
  margin-bottom: 4rem;
}


.certificates__item{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: $border-radius__medium;

  @include media(">=tablet", "screen"){
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 2rem;
    align-items: center;
  }


  h2{
    font-size: 1.25rem;
    font-weight: 500;
    color: $color__primary;
    margin-bottom: 0.5em;
  }

  p{
    color:$color__grey--900;
    font-size: 1.125rem;
    line-height: 1.5;
    max-width: 65ch;
  }

}


.certificates__image{
  aspect-ratio:  1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: $border-radius__small;
  border: 1px solid $color__grey--100;
  box-sizing: border-box;

  @include media(">=480px", "screen"){
    aspect-ratio:  4 / 5;
  }
}
