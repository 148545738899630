.wrapper{
  margin: auto;

  &--xsmall,
  &--small,
  &--medium,
  &--large,
  &--xlarge{
    padding: 0rem 1rem;

    @include media("screen", ">=tablet"){
      padding: 0rem 1rem;
    }

    @include media("screen", ">=desktop"){
      padding: 0rem 1rem;
    }
  }
}

.wrapper--xsmall{
  max-width: $max-width--xs;
}

.wrapper--small{
  max-width: $max-width--s;
}

.wrapper--medium{
  max-width: $max-width--m;
}

.wrapper--large{
  max-width: $max-width--l;
}

.wrapper--xlarge{
  max-width: $max-width--xl;
}


.site-section{
  margin-bottom: 4rem;
}
