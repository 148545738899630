$sizes: (
  "auto" : auto,
  "0": 0,
  "4": 0.25rem,
  "8": 0.5rem,
  "12": 0.75rem,
  "16": 1rem,
  "20": 1.25rem,
  "24": 1.5rem,
  "28": 1.75rem,
  "32": 2rem,
  "36": 2.25rem,
  "40": 2.5rem,
  "44": 2.75rem,
  "48": 3rem,
  "52": 3.25rem,
  "56": 3.5rem,
  "60": 3.75rem,
  "64": 4rem,
);


@each $name, $size in $sizes {

  // margin
  .mx-#{$name}{
    margin-top: $size;
    margin-bottom: $size;
  }

  .my-#{$name}{
    margin-left: $size;
    margin-right: $size;
  }

  .mt-#{$name}{
    margin-top: $size;
  }

  .mr-#{$name}{
    margin-right: $size;
  }

  .mb-#{$name}{
    margin-bottom: $size;
  }

  .ml-#{$name}{
    margin-left: $size;
  }

  // padding
  .px-#{$name}{
    padding-top: $size;
    padding-bottom: $size;
  }

  .py-#{$name}{
    padding-left: $size;
    padding-right: $size;
  }

  .pt-#{$name}{
    padding-top: $size;
  }

  .pr-#{$name}{
    padding-right: $size;
  }

  .pb-#{$name}{
    padding-bottom: $size;
  }

  .pl-#{$name}{
    padding-left: $size;
  }

}
