.offer-hero__wrapper{
  display: grid;
  grid-template-columns: 1fr;
  // filter: drop-shadow(4px 12px 16px rgba(0, 90, 168, 0.25));
}

.offer-hero__parent{
  margin-top: 4rem;
  font-size: 0.75rem;
  color: $color__grey--900;
  text-transform: uppercase;
}

.offer-hero__title{
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0.5em;
}


.offer-hero__image,
.offer-hero__content{
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.offer-hero__image{
  position: relative;

  img{
    width: 100%;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, $color__grey--900, rgba($color__grey--900, 0));
  }

}

.offer-hero__content{
  position: relative;
  z-index: 10;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  h2{
    font-size: clamp(1.875rem, 4vw, 3.5rem);
    font-weight: 800;
    max-width: 24ch;
    margin-bottom: 0.5em;
    background: linear-gradient(135deg, $color__secondary 20%, $color__primary 80%);
    background: linear-gradient(135deg, $color__secondary--100 15%, $color__secondary 75%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p{
    font-size: clamp(1.125rem, 2.5vw, 1.5rem);
    max-width: 45ch;
    margin-bottom: 2em;
    line-height: 1.5;
    color: $color__grey--000;
    font-weight: 200;
  }
}

.offer-hero__link{
  display: flex;
}



/* Offer Startseite */
.offer__wrapper{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: $border-radius__medium;
  border: 1px solid $color__primary--100;
}


.offer__grid{
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  padding: 0.5rem;
  padding-top: 2rem;
  

  @include media(">=tablet", "screen"){
    padding: 4rem;
    grid-template-columns: 1fr 1fr;
  }


  h2{
    grid-column: 1 / -1;
    font-size: 2rem;
    font-weight: 500;
    color: $color__primary;
  }
}

.offer__special-item{
  position: relative;
  grid-column: 1 / -1;
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: $border-radius__medium;
  border: 1px solid $color__primary--500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;

  @include media(">=tablet", "screen"){
    padding: 4rem;
  }

}


.offer__image{
  margin-bottom: 2rem;

  picture > img{
    border-radius: $border-radius__medium;
    border: 1px solid $color__primary--100;
    display: flex;
  }
}

.offer__content{
  h3{
    font-size: 1.5rem;
    color: $color__primary;
    font-weight: 500;
    margin-bottom: 0.75em;
  }

  p{
    font-size: 1.125rem;
    color: $color__grey--900;
    margin-bottom: 1.5em;
    line-height: 1.5;
    max-width: 65ch;
  }

  a{
    display: flex;
    color: $color__primary--900;
    font-weight: 800;
  }
}

.offer__link{
  
  svg{
    height: 2rem;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}



/* Offer List */
.offer-list{
  margin-bottom: 4rem;
}


.offer-list__container{
  padding: 2rem 4rem;
  background-color: rgba($color__primary--100, 0.5);
  border: 1px solid $color__primary--100;
  border-radius: $border-radius__medium;
  display: grid;
  grid-row-gap: 2rem;
}


.offer-list__item{
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 4rem;
  padding: 2rem;
  align-items: center;

  &:nth-child(even){
    background-color: rgba($color__primary--100, 0.5);
    border-radius: $border-radius__small;
  }
}

.offer-list__image{
  picture > img {
    border-radius: $border-radius__medium;
    border: 1px solid $color__primary--100;
  }
}

.offer-list__content{
  h3{
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5em;
    color: $color__primary;
  }

  p{
    max-width: 50ch;
    hyphens: none;
    font-size: 1.125rem;
    line-height: 1.5;
    color: $color__grey--900;
    margin-bottom: 1em;
  }

  .offer-list__link{
    display: flex;

    a{
      color: $color__secondary--400;
    }
  }
}
