.contact__banner{
  padding: 4rem 1rem;
  background-color: $color__primary--800;
}


.banner__content{
  margin-bottom: 4rem;

  h2{
    font-size: 2rem;
    font-weight: 800;
    color: $color__grey--000;
    margin-bottom: 0.25em;
  }

  p{
    font-size: 1.25rem;
    color: $color__grey--100;
  }
}


.banner__link{
  display: flex;

  a{
    padding: 1rem 1.5rem;
    background-color: #fff;
    border-radius: 0.25rem;
  }
}
